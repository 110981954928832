import Select from '@paljs/ui/Select';
import { Card, CardBody } from '@paljs/ui/Card';
import { Button } from '@paljs/ui/Button';
import { InputGroup } from '@paljs/ui/Input';
import Col from '@paljs/ui/Col';
import Row from '@paljs/ui/Row';
import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import SEO from '../../components/SEO';
import { GlobalStateData } from '../../storage/GlobalDataProvider';
import axios from 'axios';
import { EvaIcon } from '@paljs/ui/Icon';
import '../../styles/select2.css';
import ReactPaginate from 'react-paginate';

import {
  requireCheck,
  resetRequired,
  pullDownReset,
  dateFormat,
  checkNumber,
  baseUri,
  showLoader,
  hideLoader,
  randomKey,
  numFormatter,
  authCheck,
  removeErrorMsg,
  doSelect2,
  appendScript,
  buildQuery,
  ADMIN,
  getPageAccessFromState
} from '../../utils/utils';
import { getFiltered } from '../../utils/table';
import DataTable from 'react-data-table-component';

const Input = styled(InputGroup)`
  margin-bottom: 10px;
`;

const ActionBtn = styled(Button)`
  margin-bottom: 10px;
  padding: 4px;
`;
const SelectField = styled(Select)`
  margin-bottom: 10px;
`;

const initialOperations = {
  machine: '',
  operation: '',
  defaultOperation: '',
  defaultMachine: '',
  piConsumables: [],
  pioHours: '',
  speed: '',
};

const initialConsumables = {
  item: '',
  defaultItem: '',
  pctQtyInGPL: '',
  pctQtyInPercentage: '',
  pctTotalQty: '',
};

const formInit = {
  piNumber: '******',
  piType: '',
  piColor: '',
  piDate: dateFormat(new Date()),
  customer: '',
  defaultUser: '',
  customerSort: '',
  defaultSort: '',
  finishedWidth: 0,
  finishedGLM: 0,
  greyInMeter: 0,
  greyInKg: 0,
  sampleReceived: true,
  status: 'active',
  defaultSampleReceived: { label: 'Yes', value: true },
  defaultStatus: { label: 'active', value: 'active' },
  piOperations: [],
};

const ProcessInstructions = () => {
  const [totalPage, setTotalPage] = useState(0);
  const [page, setPage] = useState(1);
  const [formInput, setFormInput] = useState(() => formInit);
  const [pickId, setPickId] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showData, setShowData] = useState([]);
  const [restrictedEditUsers, setRestrictedUsers] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [sortOptions, setSortOptions] = useState([]);
  const [operationOptions, setOperationOptions] = useState([]);
  const [itemOptions, setItemOptions] = useState([]);
  const [tableFilter, setTableFilter] = useState('');
  const [tableData, setTableData] = useState([]);
  const [machineOptions, setMachineOptions] = useState([]);

  const authState = useContext(GlobalStateData);
  const pageAccess = getPageAccessFromState(authState);
  const [isSave, setSave] = useState(pageAccess.write === 1);

  const sampleBoolean = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];
  const statusOptions = [
    { label: 'active', value: 'active' },
    { label: 'inactive', value: 'inactive' },
  ];
  useEffect(() => {
    authCheck(authState.authData);
    appendScript();
  }, []);

  useEffect(() => {
    setRestrictedUsers(['ublmtpadmin@gmail.com']);
  }, [authState]);

  useEffect(() => {
    getApi();
  }, [page]);

  const handlePagination = (event) => {
    setPage(event.selected + 1);
  };

  const handleModalOpen = (id) => {
    document.getElementsByTagName('body')[0].style.position = 'relative';
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
    showPi(id);
  };

  const handleModalClose = (event) => {
    setIsModalOpen(false);
    document.getElementsByTagName('body')[0].style.position = 'unset';
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
  };

  const modalWrapperClick = (e) => {
    if (e.target.classList.contains('popup-parent')) {
      handleModalClose();
    }
  };

  const popup = isModalOpen ? (
    <div className="popup-parent" onClick={(e) => modalWrapperClick(e)}>
      <div className="popup-wrapper">
        <div
          className="pop-up-close"
          onClick={() => {
            handleModalClose();
          }}
        >
          x
        </div>
        <div className="pop-content">
          <h5>PI Operations</h5>
          {showData &&
            showData.map((row, i) => (
              <div className="div-part1 operation-list" key={row.toString}>
                <div className="div-part1 operation-part">
                  <p>
                    Operation:{' '}
                    <span>
                      {row.operation.data
                        ? row.operation.data.attributes.operationCode +
                          ' - ' +
                          row.operation.data.attributes.description
                        : ''}
                    </span>
                  </p>
                  <p>
                    Machine:{' '}
                    <span>
                      {row.machine.data
                        ? row.machine.data.attributes.machineCode + ' - ' + row.machine.data.attributes.description
                        : ''}
                    </span>
                  </p>
                  <p>
                    Hours: <span>{row.pioHours}</span>
                  </p>
                  <p>
                    Speed: <span>{row.speed}</span>
                  </p>
                  <table className="stripped bordered hover pioperations-table">
                    <thead>
                      <tr>
                        <th width="150px">Item</th>
                        <th width="100px">GPL Qty</th>
                        <th width="100px">GPL in %</th>
                        <th width="100px">Total Qty</th>
                      </tr>
                    </thead>
                    <tbody>
                      {row.piConsumables &&
                        row.piConsumables.map((row2, j) => (
                          <tr key={randomKey()}>
                            <td>{row2.item.data.attributes.itemCode + ' - ' + row2.item.data.attributes.itemName}</td>
                            <td>{row2.pctQtyInGPL}</td>
                            <td>{row2.pctQtyInPercentage}</td>
                            <td>{row2.pctTotalQty}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  ) : null;

  const AddOperation = () => {
    let initialOp = { ...initialOperations };
    let newOps = [...formInput.piOperations, { ...initialOp }];
    setFormInput({ ...formInput, piOperations: newOps });
  };

  const removeOperations = (index) => {
    let operations = formInput.piOperations.filter((value, key) => {
      return key !== index;
    });
    setFormInput({ ...formInput, piOperations: operations });
  };

  const addItem = (key) => {
    let ops = formInput.piOperations[key];
    let initialItem = { ...initialConsumables };
    let newIndent = [...ops.piConsumables, { ...initialItem }];
    let copy = formInput.piOperations.map((element, ki) => {
      if (ki == key) {
        element.piConsumables = newIndent;
      }
      return element;
    });
    setFormInput({
      ...formInput,
      piOperations: copy,
    });
  };

  const removeItems = (ops, item) => {
    let options = formInput.piOperations.filter((value, key) => {
      return key === ops;
    });

    let items = options[0].piConsumables.filter((value, key) => {
      return key !== item;
    });
    formInput.piOperations[ops].piConsumables = items;
    setFormInput({ ...formInput, piOperations: formInput.piOperations });
  };

  const handleChange = (event) => {
    resetRequired(event);
    const { name, value } = event.target;
    let res;
    switch (event.target.name) {
      case 'finishedWidth':
        res = checkNumber(event.target, '3.0');
        break;
      case 'finishedGLM':
        res = checkNumber(event.target, '3.3');
        break;
      case 'greyInMeter':
        res = checkNumber(event.target, '7.2');
        break;
      case 'greyInKg':
        res = checkNumber(event.target, '5.3');
        break;
      default:
        res = value;
    }
    setFormInput({ ...formInput, [name]: value });
  };

  const clearFormData = () => {
    setSave(pageAccess.write === 1);
    setPickId('');
    setFormInput({ ...formInit });
    resetRequired();
  };

  const getMachines = () => {
    axios
      .get(
        baseUri() +
          `machines?populate[0]=groupOps&populate[1]=groupOps.group&populate[2]=groupOps.operations&sort[0]=id:desc`,
        {
          headers: {
            authorization: 'Bearer ' + authState.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      )
      .then((response) => {
        let options = [];
        response.data.data.forEach(function (item, index) {
          options.push({ label: item.attributes.machineCode + ' - ' + item.attributes.description, value: item.id });
        });
        setMachineOptions(options);
      })
      .catch((error) => {
        //return  error;
      });
  };

  const getApi = () => {
    showLoader();
    axios
      .get(
        baseUri() +
          buildQuery(
            'process-instructions?populate[0]=customer&populate[1]=customerSort&populate[2]=piOperations&populate[3]=piOperations.piConsumables&populate[4]=piOperations.piConsumables.item&populate[5]=piOperations.operation&populate[6]=piOperations.machine&sort[0]=id:desc&pagination[page]=' +
              page +
              '&pagination[pageSize]=20',
            tableFilter,
            filterColumns,
          ),
        {
          headers: {
            authorization: 'Bearer ' + authState.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      )
      .then((response) => {
        setTableData(response.data.data);
        setTotalPage(response.data.meta.pagination.pageCount);
        hideLoader();
        if (page == 1 && totalPage == 0) {
          getCustomers();
          getOperations();
          getItems();
          getMachines();
        }
      })
      .catch((error) => {
        //return  error;
      });
  };

  const getCustomers = () => {
    axios
      .get(baseUri() + 'users?filters[userType][$eq]=4', {
        headers: {
          authorization: 'Bearer ' + authState.authData,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        let options = [];
        response.data.forEach(function (item, index) {
          options.push({ label: item.userCode + ' - ' + item.name, value: item.id });
        });
        setUserOptions(options);
      })
      .catch((error) => {
        //return  error;
      });
  };

  const getOperations = () => {
    axios
      .get(baseUri() + 'operations', {
        headers: {
          authorization: 'Bearer ' + authState.authData,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        let options = [];
        response.data.data.forEach(function (item, index) {
          options.push({ label: item.attributes.operationCode + ' - ' + item.attributes.description, value: item.id });
        });
        setOperationOptions(options);
      })
      .catch((error) => {
        //return  error;
      });
  };

  const getItems = () => {
    axios
      .get(baseUri() + 'items', {
        headers: {
          authorization: 'Bearer ' + authState.authData,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        let items = [];
        response.data.data.forEach(function (item, index) {
          items.push({ label: item.attributes.itemCode + ' - ' + item.attributes.itemName, value: item.id });
        });
        setItemOptions(items);
      })
      .catch((error) => {
        //return  error;
      });
  };

  const getSorts = (cId) => {
    showLoader();
    axios
      .get(baseUri() + `user-sorts?filters[user][id][$eq]=${cId}`, {
        headers: {
          authorization: 'Bearer ' + authState.authData,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        let options = [];
        response.data.data.forEach(function (item, index) {
          options.push({ label: item.attributes.sortName, value: item.id });
        });
        setSortOptions(options);
        hideLoader();
      })
      .catch((error) => {
        //return  error;
      });
  };

  const userChange = (event) => {
    setFormInput({
      ...formInput,
      customer: event.value,
      defaultUser: { label: event.label, value: event.value },
      customerSort: '',
      defaultSort: [],
    });
    pullDownReset('customer');
    getSorts(event.value);
  };

  const sortChange = (event) => {
    setFormInput({ ...formInput, customerSort: event.value, defaultSort: { label: event.label, value: event.value } });
    pullDownReset('customerSort');
  };

  const operationChange = (event, index) => {
    formInput.piOperations[index]['defaultOperation'] = { label: event.label, value: event.value };
    formInput.piOperations[index]['operation'] = event.value;
    setFormInput({ ...formInput, piOperations: formInput.piOperations });
    pullDownReset('piOperations-' + index + '-operation');
  };

  const machineChange = (event, index) => {
    formInput.piOperations[index]['defaultMachine'] = { label: event.label, value: event.value };
    formInput.piOperations[index]['machine'] = event.value;
    setFormInput({ ...formInput, piOperations: formInput.piOperations });
    pullDownReset('piOperations-' + index + '-machine');
  };

  const groupInputChange = (e, index) => {
    resetRequired(e);
    const { name, value } = e.target;
    let res;
    switch (e.target.name) {
      case 'pioHours':
        res = checkNumber(e.target, '2.2');
        break;
      case 'speed':
        res = checkNumber(e.target, '3.0');
      default:
        res = value;
    }
    formInput.piOperations[index][name] = res;
    let field = name == 'pioHours' ? 'speed' : 'pioHours';
    formInput.piOperations[index][`${field}Disabled`] = res;
    formInput.piOperations[index][field] = res ? 0 : '';
    resetRequired(false, document.getElementById(`${field}${index}`), true);
    setFormInput({ ...formInput, piOperations: formInput.piOperations });
  };

  const itemChange = (event, ops, item) => {
    formInput.piOperations[ops].piConsumables[item]['item'] = event.value;
    formInput.piOperations[ops].piConsumables[item]['defaultItem'] = { label: event.label, value: event.value };
    setFormInput({ ...formInput, piOperations: formInput.piOperations });
    pullDownReset('piOperations-' + ops + '-piConsumables-' + item + '-item');
  };

  const itemInputChange = (event, ops, item) => {
    resetRequired(event);
    const { name, value } = event.target;
    let res;
    switch (event.target.name) {
      case 'pctQtyInGPL':
        res = checkNumber(event.target, '4.3');
        break;
      case 'pctQtyInPercentage':
        res = checkNumber(event.target, '4.4');
      default:
        res = value;
    }
    formInput.piOperations[ops].piConsumables[item][name] = res;
    let field;
    if (name == 'pctQtyInGPL') {
      field = ['pctQtyInPercentage', 'pctTotalQty'];
    } else if (name == 'pctQtyInPercentage') {
      field = ['pctQtyInGPL', 'pctTotalQty'];
    } else {
      field = ['pctQtyInGPL', 'pctQtyInPercentage'];
    }

    field.forEach((it) => {
      resetRequired(false, document.getElementById(`${it}${item}`), true);
      formInput.piOperations[ops].piConsumables[item][`${it}Disabled`] = res;
      formInput.piOperations[ops].piConsumables[item][it] = res ? 0 : '';
    });
    setFormInput({ ...formInput, piOperations: formInput.piOperations });
  };

  const handleSampleChange = (event) => {
    setFormInput({
      ...formInput,
      sampleReceived: event.value,
      defaultSampleReceived: { label: event.label, value: event.value },
    });
    pullDownReset('sampleReceived');
  };
  const handleStatusChange = (event) => {
    setFormInput({
      ...formInput,
      status: event.value,
      defaultStatus: { label: event.label, value: event.value },
    });
  };
  const postApi = () => {
    const postData = {
      data: {
        ...formInput,
      },
    };

    let validate = requireCheck(postData);
    if (!validate) {
      return false;
    }
    for (const key in postData['data']) {
      if (postData['data'][key] == '' && postData['data'][key] == 'sampleReceived') {
        delete postData['data'][key];
      }
    }
    showLoader();
    if (pickId) {
      axios
        .put(baseUri() + 'process-instructions' + '/' + pickId, postData, {
          headers: {
            authorization: 'Bearer ' + authState.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          clearFormData();
          getApi();
        })
        .catch((error) => {
          //return  error;
        });
    } else {
      axios
        .post(baseUri() + 'process-instructions', postData, {
          headers: {
            authorization: 'Bearer ' + authState.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          clearFormData();
          getApi();
        })
        .catch((error) => {
          //return  error;
        });
    }
  };

  const editPi = (id, copy = false) => {
    showLoader();
    clearFormData();
    axios
      .get(
        baseUri() +
          `process-instructions/${id}?populate[0]=customer&populate[1]=customerSort&populate[2]=piOperations&populate[3]=piOperations.piConsumables&populate[4]=piOperations.piConsumables.item&populate[5]=piOperations.operation&populate[6]=piOperations.machine&populate[7]=status`,
        {
          headers: {
            authorization: 'Bearer ' + authState.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      )
      .then((response) => {
        response = response.data.data;
        // Check whether Copy or Edit
        let piNum;
        if (!copy) {
          setSave(pageAccess.edit === 1);
          setPickId(response.id);
          piNum = response.attributes.piNumber;
        } else {
          setSave(pageAccess.write === 1);
          piNum = '*******';
        }
        let date = dateFormat(copy ? new Date().toISOString() : response.attributes.piDate);
        let defaultSample = {
          label: response.attributes.sampleReceived ? 'Yes' : 'No',
          value: response.attributes.sampleReceived ? true : false,
        };
        let defaultStatus = {
          label: response.attributes.status ? response.attributes.status : 'active',
          value: response.attributes.status ? response.attributes.status : 'active',
        };
        //Work on this
        getSorts(response.attributes.customer.data.id);
        let operations = [];
        response.attributes.piOperations.forEach((op, index) => {
          let consumes = [];
          op.piConsumables.forEach((pc) => {
            consumes.push({
              id: pc.id,
              pctQtyInGPL: pc.pctQtyInGPL,
              pctQtyInPercentage: pc.pctQtyInPercentage,
              pctTotalQty: pc.pctTotalQty,
              item: pc.item?.data?.id,
              defaultItem: {
                label: pc.item?.data?.attributes.itemCode + ' - ' + pc.item?.data?.attributes.itemName,
                value: pc.item?.data?.id,
              },
            });
          });

          operations.push({
            id: op.id,
            pioHours: op.pioHours,
            speed: op.speed,
            machine: op.machine?.data?.id,
            defaultMachine: {
              label: op.machine?.data?.attributes?.machineCode + ' - ' + op.machine?.data?.attributes?.description,
              value: op.machine?.data.id,
            },
            operation: op.operation?.data?.id,
            defaultOperation: {
              label: op.operation?.data?.attributes.operationCode + ' - ' + op.operation?.data?.attributes.description,
              value: op.operation?.data?.id,
            },
            piConsumables: consumes,
          });
        });
        setFormInput({
          ...formInit,
          piNumber: piNum,
          piType: response.attributes.piType,
          piColor: response.attributes.piColor,
          piDate: date,
          customer: response.attributes.customer.data.id,
          defaultUser: {
            label:
              response.attributes.customer.data.attributes.userCode +
              ' - ' +
              response.attributes.customer.data.attributes.name,
            value: response.attributes.customer.data.id,
          },
          customerSort: response.attributes.customerSort.data.id,
          defaultSort: {
            label: response.attributes.customerSort.data.attributes.sortName,
            value: response.attributes.customerSort.data.id,
          },
          finishedWidth: response.attributes.finishedWidth ?? 0,
          finishedGLM: response.attributes.finishedGLM ?? 0,
          greyInMeter: response.attributes.greyInMeter ?? 0,
          greyInKg: response.attributes.greyInKg ?? 0,
          sampleReceived: response.attributes.sampleReceived,
          status: response.attributes.status || 'active',
          defaultStatus: defaultStatus,
          defaultSampleReceived: defaultSample,
          piOperations: operations,
        });
        hideLoader();
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      })
      .catch((error) => {
        //return  error;
      });
  };
  console.log(formInput);
  const showPi = (id) => {
    showLoader();
    axios
      .get(
        baseUri() +
          `process-instructions/${id}?populate[0]=customer&populate[1]=customerSort&populate[2]=piOperations&populate[3]=piOperations.piConsumables&populate[4]=piOperations.piConsumables.item&populate[5]=piOperations.operation&populate[6]=piOperations.machine`,
        {
          headers: {
            authorization: 'Bearer ' + authState.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      )
      .then((response) => {
        setShowData(response.data.data.attributes.piOperations);
        setIsModalOpen(true);
        hideLoader();
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      })
      .catch((error) => {
        //return  error;
      });
  };

  const deletePi = (id) => {
    const removeGroup = window.confirm('Would you like to delete this PI?');
    if (removeGroup) {
      showLoader();
      setPickId(id);
      axios
        .delete(baseUri() + 'process-instructions/' + id, {
          headers: {
            authorization: 'Bearer ' + authState.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          clearFormData();
          getApi();
        })
        .catch((error) => {
          //return  error;
        });
    }
  };

  const columns = [
    {
      name: 'Action',
      cell: (row) => (
        <div className="action-container">
          <ActionBtn
            data-tag="allowRowEvents"
            className="abtn-color"
            id={'editData' + row.id}
            onClick={() => {
              editPi(row.id);
            }}
          >
            {' '}
            <EvaIcon name="edit" />{' '}
          </ActionBtn>
          {row.attributes.piOperations.length ? (
            <ActionBtn
              data-tag="allowRowEvents"
              className="btn-rad ml-10"
              onClick={() => {
                handleModalOpen(row.id);
              }}
            >
              {' '}
              <EvaIcon name="eye" />{' '}
            </ActionBtn>
          ) : (
            <></>
          )}
          <ActionBtn
            data-tag="allowRowEvents"
            className="abtncopy-color"
            onClick={() => {
              editPi(row.id, true);
            }}
          >
            {' '}
            <EvaIcon name="copy" />{' '}
          </ActionBtn>
          {pageAccess.delete === 1 && (
            <ActionBtn
              data-tag="allowRowEvents"
              className="abtndel-color"
              onClick={() => {
                deletePi(row.id);
              }}
            >
              {' '}
              <EvaIcon name="trash" />{' '}
            </ActionBtn>
          )}
        </div>
      ),
    },
    {
      name: 'Pi Number',
      selector: (row) => `${row.attributes.piNumber}`,
      sortable: true,
      maxWidth: '20%',
    },
    {
      name: 'Process Type',
      selector: (row) => row.attributes.piType,
      sortable: true,
      maxWidth: '5%',
    },
    {
      name: 'Color',
      selector: (row) => row.attributes.piColor,
      sortable: true,
      maxWidth: '5%',
    },
    {
      name: 'Pi Date',
      selector: (row) => `${dateFormat(row.attributes.piDate)}`,
      sortable: true,
      maxWidth: '10%',
    },
    {
      name: 'Customer',
      selector: (row) =>
        `${
          row.attributes.customer.data
            ? row.attributes.customer.data.attributes.userCode + ' - ' + row.attributes.customer.data.attributes.name
            : ''
        }`,
      sortable: true,
      maxWidth: '25%',
    },
    {
      name: 'Sort',
      selector: (row) =>
        `${row.attributes.customerSort.data ? row.attributes.customerSort.data.attributes.sortName : ''}`,
      sortable: true,
      maxWidth: '20%',
    },
    {
      name: 'Finished Width',
      selector: (row) => numFormatter(row.attributes.finishedWidth),
      sortable: true,
      maxWidth: '5%',
    },
    {
      name: 'Finished GLM',
      selector: (row) => numFormatter(row.attributes.finishedGLM),
      sortable: true,
      maxWidth: '5%',
    },
    {
      name: 'Grey in KG',
      selector: (row) => numFormatter(row.attributes.greyInKg),
      sortable: true,
      maxWidth: '5%',
    },
    {
      name: 'Grey in Meter',
      selector: (row) => numFormatter(row.attributes.greyInMeter),
      sortable: true,
      maxWidth: '5%',
    },
    {
      name: 'Sample Received',
      selector: (row) => `${row.attributes.sampleReceived ? 'Yes' : 'No'}`,
      sortable: true,
      maxWidth: '5%',
    },
    {
      name: 'Status',
      selector: (row) => `${row.attributes.status || 'active'}`,
      sortable: true,
      maxWidth: '20%',
    },
  ];

  const filterColumns = [
    'customer.userCode',
    'customer.name',
    'customerSort.sortName',
    'piNumber',
    'piDate',
    'sampleReceived',
    'finishedGLM',
    'finishedWidth',
    'greyInKg',
    'greyInMeter',
    'piType',
    'piColor',
  ];

  return (
    <>
      {popup}
      <SEO title="Process Instruction" />
      <Row>
        <Col breakPoint={{ xs: 12, md: 12 }}>
          <Card>
            <header> Process Instruction</header>
            <CardBody>
              <Row>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>PI Number</label>
                  <Input fullWidth size="Small">
                    <input
                      type="text"
                      name="piNumber"
                      placeholder="PI Number"
                      value={formInput.piNumber}
                      disabled="disabled"
                      className="piNumber"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>Process Type</label>
                  <Input fullWidth size="Small">
                    <input
                      type="text"
                      name="piType"
                      placeholder="Process Type"
                      onChange={(e) => handleChange(e)}
                      value={formInput.piType}
                      maxLength="50"
                      className="piType"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>Color</label>
                  <Input fullWidth size="Small">
                    <input
                      type="text"
                      name="piColor"
                      placeholder="Color"
                      onChange={(e) => handleChange(e)}
                      value={formInput.piColor}
                      maxLength="25"
                      className="piColor"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>PI Date</label>
                  <Input fullWidth size="Small">
                    <input
                      type="date"
                      name="piDate"
                      placeholder="PI Date"
                      onChange={(e) => handleChange(e)}
                      value={formInput.piDate}
                      readOnly={restrictedEditUsers.includes(authState.authEmail)}
                      className="piDate"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>Select Customer</label>
                  <SelectField
                    fullWidth
                    onChange={(e) => userChange(e)}
                    options={userOptions}
                    id="customer"
                    name="customer"
                    placeholder="Select Customer"
                    value={formInput.defaultUser}
                    className="pull-down require"
                  />
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>Select Sort</label>
                  <SelectField
                    fullWidth
                    onChange={(e) => sortChange(e)}
                    options={sortOptions}
                    id="customerSort"
                    name="customerSort"
                    placeholder="Select Sort"
                    value={formInput.defaultSort}
                    className="pull-down require"
                  />
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>Finished Width</label>
                  <Input fullWidth size="Small">
                    <input
                      type="text"
                      name="finishedWidth"
                      placeholder="Finished Width"
                      maxLength="4"
                      onChange={(e) => handleChange(e)}
                      value={formInput.finishedWidth}
                      className="finishedWidth"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>Finished GLM</label>
                  <Input fullWidth size="Small">
                    <input
                      type="text"
                      name="finishedGLM"
                      placeholder="Finished GLM"
                      onChange={(e) => handleChange(e)}
                      maxLength="7"
                      value={formInput.finishedGLM}
                      className="finishedGLM"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>Grey In Meter</label>
                  <Input fullWidth size="Small">
                    <input
                      type="text"
                      name="greyInMeter"
                      placeholder="Grey In Meter"
                      onChange={(e) => handleChange(e)}
                      value={formInput.greyInMeter}
                      maxLength="10"
                      className="greyInMeter"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>Grey In KG</label>
                  <Input fullWidth size="Small">
                    <input
                      type="text"
                      name="greyInKg"
                      placeholder="Grey In KG"
                      maxLength="9"
                      onChange={(e) => handleChange(e)}
                      value={formInput.greyInKg}
                      className="greyInKg"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>Sample Received</label>
                  <SelectField
                    fullWidth
                    onChange={(e) => handleSampleChange(e)}
                    options={sampleBoolean}
                    id="sampleReceived"
                    name="sampleReceived"
                    placeholder="Sample Received?"
                    value={formInput.defaultSampleReceived}
                    className="pull-down"
                  />
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>Status</label>
                  <SelectField
                    fullWidth
                    onChange={(e) => handleStatusChange(e)}
                    options={statusOptions}
                    id="status"
                    name="status"
                    placeholder="status"
                    value={formInput.defaultStatus}
                    className="pull-down"
                  />
                </Col>
                {formInput.piOperations.length > 0 && (
                  <Col breakPoint={{ xs: 12 }} className="machine-util-title">
                    PI Operations
                  </Col>
                )}
                <Col breakPoint={{ xs: 12, md: 12 }} className="pi-operation-container">
                  {formInput.piOperations.map((item, key) => {
                    return (
                      <>
                        <Row key={item.toString} className="pi-input-container machine-inputs">
                          <Col breakPoint={{ xs: 12, md: 3 }}>
                            <label>Operations</label>
                            <SelectField
                              fullWidth
                              onChange={(e) => operationChange(e, key)}
                              options={operationOptions}
                              value={item.defaultOperation}
                              id={'piOperations-' + key + '-operation'}
                              placeholder="Select Operation"
                              className="pull-down require"
                            />
                          </Col>
                          <Col breakPoint={{ xs: 12, md: 3 }}>
                            <label>Machine</label>
                            <SelectField
                              fullWidth
                              onChange={(e) => machineChange(e, key)}
                              options={machineOptions}
                              value={item.defaultMachine}
                              id={'piOperations-' + key + '-machine'}
                              placeholder="Select Operation"
                              className="pull-down require"
                            />
                          </Col>
                          <Col breakPoint={{ xs: 12, md: 3 }}>
                            <label>Hours </label>
                            <Input fullWidth size="Small">
                              <input
                                type="text"
                                placeholder="Hours"
                                name="pioHours"
                                id={`pioHours${key}`}
                                onChange={(e) => groupInputChange(e, key)}
                                value={item.pioHours}
                                disabled={item.pioHoursDisabled}
                                className="hours require"
                              />
                            </Input>
                          </Col>
                          <Col className="endTimer" breakPoint={{ xs: 12, md: 3 }}>
                            <label>Speed </label>
                            <Input fullWidth size="Small">
                              <input
                                type="text"
                                placeholder="speed"
                                name="speed"
                                id={`speed${key}`}
                                onChange={(e) => groupInputChange(e, key)}
                                value={item.speed}
                                disabled={item.speedDisabled}
                                className="speed require"
                              />
                            </Input>
                          </Col>
                          <div onClick={() => removeOperations(key)} className="div-part10">
                            <label className="div-full"></label>
                            <label className="delBtn piOperationDelete div-full">
                              <span>-</span>
                            </label>
                          </div>
                          <Col breakPoint={{ xs: 12, md: 12 }}>
                            {item.piConsumables &&
                              item.piConsumables.map((field, kii) => {
                                return (
                                  <Row key={field.toString} className="item-inputs">
                                    <Col breakPoint={{ xs: 12, md: 3 }}>
                                      {kii == 0 && <label>Item</label>}
                                      <SelectField
                                        fullWidth
                                        onChange={(e) => itemChange(e, key, kii)}
                                        options={itemOptions}
                                        placeholder="Select Item"
                                        value={field.defaultItem}
                                        id={'piOperations-' + key + '-piConsumables-' + kii + '-item'}
                                        className="pull-down require"
                                      />
                                    </Col>
                                    <Col breakPoint={{ xs: 12, md: 2 }}>
                                      {kii == 0 && <label>GPL Qty</label>}
                                      <Input fullWidth size="Small">
                                        <input
                                          type="text"
                                          placeholder="GPL Qty"
                                          name="pctQtyInGPL"
                                          id={`pctQtyInGPL${kii}`}
                                          className="require pctQtyInGPL"
                                          disabled={field.pctQtyInGPLDisabled}
                                          onChange={(e) => itemInputChange(e, key, kii)}
                                          value={field.pctQtyInGPL}
                                        />
                                      </Input>
                                    </Col>
                                    <Col breakPoint={{ xs: 12, md: 2 }}>
                                      {kii == 0 && <label>Qty In %</label>}
                                      <Input fullWidth size="Small">
                                        <input
                                          type="text"
                                          placeholder="Qty In %"
                                          name="pctQtyInPercentage"
                                          id={`pctQtyInPercentage${kii}`}
                                          className="require pctQtyInPercentage"
                                          disabled={field.pctQtyInPercentageDisabled}
                                          onChange={(e) => itemInputChange(e, key, kii)}
                                          value={field.pctQtyInPercentage}
                                        />
                                      </Input>
                                    </Col>
                                    <Col breakPoint={{ xs: 12, md: 2 }}>
                                      {kii == 0 && <label>Total Qty </label>}
                                      <Input fullWidth size="Small">
                                        <input
                                          type="text"
                                          id={`pctTotalQty${kii}`}
                                          name="pctTotalQty"
                                          onChange={(e) => itemInputChange(e, key, kii)}
                                          placeholder="Total Qty"
                                          disabled={field.pctTotalQtyDisabled}
                                          className="pctTotalQty"
                                          value={field.pctTotalQty}
                                        />
                                      </Input>
                                    </Col>
                                    <Col className="removeitem" breakPoint={{ xs: 12, md: 2 }}>
                                      <div onClick={() => removeItems(key, kii)} className="div-part10">
                                        <label className="div-full"></label>
                                        <label className="delBtn delItem div-full">
                                          <span>-</span>
                                        </label>
                                      </div>
                                    </Col>
                                  </Row>
                                );
                              })}
                            <div className="machinutilitemadd">
                              <button onClick={() => addItem(key)} className="addItembtn">
                                Add Item +
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </>
                    );
                  })}
                </Col>
                <Col breakPoint={{ xs: 12, md: 12 }} className="PiAddBtnContainer">
                  <Button className="addPibtn" onClick={AddOperation}>
                    Add PI Operations <EvaIcon name="plus" className="plusIcon" />
                  </Button>
                </Col>
                <Col breakPoint={{ xs: 12, md: 12 }} className="mt20">
                  <Button
                    status="Success"
                    type="button"
                    id="post-data"
                    className="btnrad"
                    shape=""
                    disabled={!isSave}
                    halfWidth
                    onClick={postApi}
                  >
                    Save
                  </Button>
                  &nbsp; &nbsp;
                  <Button status="danger" type="button" className="btnrad" shape="" halfWidth onClick={clearFormData}>
                    Reset
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col breakPoint={{ xs: 12, md: 12 }}>
          <Card>
            <header>Process Instructions</header>
            <CardBody>
              <Input fullWidth size="Small">
                <input
                  type="text"
                  onChange={(e) => setTableFilter(e.target.value)}
                  placeholder="Filter"
                  name="tableFilter"
                  value={tableFilter}
                />
              </Input>
              <Button
                onClick={() => {
                  if (page == 1) {
                    getApi();
                  } else {
                    setPage(1);
                  }
                }}
              >
                Filter
              </Button>
              <DataTable
                columns={columns}
                data={tableData}
                fixedHeader
                allowOverflow={false}
                overflowY={false}
                overflowX={false}
                wrap={false}
                highlightOnHover={true}
              />
              <ReactPaginate
                className="pagination"
                breakLabel="..."
                nextLabel="Next"
                onPageChange={handlePagination}
                pageRangeDisplayed={5}
                pageCount={totalPage}
                previousLabel="Previous"
                renderOnZeroPageCount={null}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default ProcessInstructions;
